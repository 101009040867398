@charset "UTF-8";

/*SIDEMENU.JS*/

.disable_ {
	ponter-events: none; }

.sm-main {
  font-size: 10px;
  position: fixed;
  right: 0;
  left: auto;
  top: 0;
  height: 100%;
  width: 260px;
  z-index: 20;
  backface-visibility: hidden;
  transform: translate(100%, 0);
  transform: translate3d(100%, 0, 0); }

.sm-overlay {
	background: rgba(0,0,0,.7);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10; }

.sm-main-left {
  right: auto;
  left: 0;
  transform: translate(-100%, 0);
  transform: translate3d(-100%, 0, 0); }

.sm {
  transition: transform 0.7s cubic-bezier(0.65, 0.22, 0, 0.98), opacity 0.7s linear;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 0; }

.sm-body {
	background: #FFF;
	height: 100%; }

.sm-scroller {
	overflow-y: auto;
	height: 100%;
	width: 100%; }

.sm-main > .sm-show {
  transform: translate(-100%, 0);
  transform: translate3d(-100%, 0, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.29);
  opacity: 1; }

.sm-main-left {
  .sm {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0); }
  > .sm-show {
    transform: translate(100%, 0);
    transform: translate3d(100%, 0, 0); } }

.sm-title {
  font-size: 2em;
  font-weight: 300;
  margin: 0;
  padding: 1em; }

.sm-item-label, .sm a {
  color: #888;
  cursor: pointer;
  text-decoration: none; }

.sm-item-more > .sm-item-label::after {
  display: block;
  position: absolute;
  content: "»";
  right: 0;
  font-size: 1.7em;
  color: #ADADAD;
  top: 0;
  font-family: arial;
  line-height: 1;
  padding: .36em 0.9em;
  font-weight: 100; }

.sm-content {
	&> ul {
		padding: 0;
		margin: 0; } }

.sm-item {
	list-style: none;
	margin: 0; }

html.sm-desktop {
	.sm-item-label {
	  &:hover {
	    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
	    background: rgba(0, 0, 0, 0.2);
	    color: #FFF;
	    &::after {
	      color: white; } } } }

html.sm-touch {
	.sm-scroller {
		overflow: scroll;
		-webkit-overflow-scrolling: touch; }
	.sm-content {
		min-height: 105%; } }

.sm-item-label {
  position: relative;
  transition: background .3s,box-shadow .3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
  display: block;
  font-size: 1.4em;
  line-height: 2em;
  outline: 0;
  padding: .7em 1.8em .7em 1em;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.1); }

.sm-back {
	cursor: pointer;
	background: #FFF;
	color:#00b76f {}
	display: block;
	font-size: 1.3em;
	font-weight: 700;
	letter-spacing: 1px;
	outline: 0;
	padding: 1em 1em 1em 2.5em;
	position: relative;
	text-transform: uppercase;
	&:hover {
		background: #00b76f;
		color: #FFF;
		&::after {
			color: white; } }
	&::after {
		color: #00b76f;
		display: block;
		position: absolute;
		content: "«";
		left: 0;
		font-size: 2em;
		top: 0;
		font-family: arial;
		font-weight: 100;
		line-height: 1;
		padding: 0.28em 0.5em; } }
